import React, { useState, useEffect } from "react";
import axios from "axios";
import dompurify from "dompurify";
import * as XLSX from "xlsx";

const ItemUserDisplayDetail = () => {
  const sanitizer = dompurify.sanitize;
  const [items, setItems] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    const storedItems = localStorage.getItem("selectedItems");
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      setItems(parsedItems);
      localStorage.removeItem("selectedItems");
    }
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      const [itemId, lang, mod, userLvl, isPassed, startDate, endDate, excludeAdmin] = items;
      axios
        .get(
          `/api/adminReport/itemUserCorrelationDetails?site=oda&lang=${lang}&itemId=${itemId}&mod=${mod}&userLvl=${userLvl}&isPassed=${isPassed}&startDate=${startDate}&endDate=${endDate}&excludeAdmin=${excludeAdmin}`
        )
        .then((res) => {
          //issue #468 - validate the incoming data due to data parsing errors causing 404s for SMEs
          const incomingData = JSON.parse(decodeURIComponent(res.data));
          if (typeof incomingData === "object" && incomingData.hasOwnProperty("sessionData") && incomingData.sessionData.length > 0) {
            setContent(incomingData);
          } else {
            console.log("API data error.")
          }
        })
        .catch((error) => {
          console.log("API request failed");
        });
    }
  }, [items]);

  const renderQuestionContent = (item, mod) => {
    if (item.questionText) {
      if (!Array.isArray(item.questionText)) {
        return item.questionText;
      } else {
        return item.questionText.join("&nbsp;");
      }
    } else if (item.question.value) {
      if (!Array.isArray(item.question)) {
        return item.question.value;
      } else {
        return item.question.map((q) => q.value).join("&nbsp;");
      }
    } else {
      return "No question available";
    }
  };

  const renderKeyContent = (item) => {
    // For short answer questions
    if (item.answerKeywords && item.answerKeywords.length > 0) {
      if (Array.isArray(item.answerKeywords)) {
        return item.answerKeywords
          .map((keyGroup) => {
            if (Array.isArray(keyGroup)) {
              return keyGroup
                .map((key) => {
                  if (key && key.matchingPatterns) {
                    return (
                      "<strong>Matching Patterns:</strong> " +
                      key.matchingPatterns.join("||") +
                      "<br/><strong>Individual Score:</strong> " +
                      key.individualScore
                    );
                  }
                  return "N/A";
                })
                .join("<br/><br/>");
            } else if (keyGroup && keyGroup.matchingPatterns) {
              return (
                "<strong>Matching Patterns:</strong> " +
                keyGroup.matchingPatterns.join("||") +
                "<br/><strong>Individual Score:</strong> " +
                keyGroup.individualScore
              );
            }
            return "N/A";
          })
          .join("<br/><br/>");
      } else if (item.answerKeywords.matchingPatterns) {
        return (
          "<strong>Matching Patterns:</strong> " +
          item.answerKeywords.matchingPatterns.join("||") +
          "<br/><strong>Individual Score:</strong> " +
          item.answerKeywords.individualScore
        );
      }
      // For multiple choice questions
    } else if (item.multipleChoiceAnsNums !== undefined) {
      if (Array.isArray(item.multipleChoiceAnsNums)) {
        if (item.multipleChoiceAnsNums.length > 0) {
          return `${item.multipleChoiceAnsNums[0]}`;
        } else {
          return `${item.multipleChoiceAnsNums}`;
        }
      } else {
        return `${item.multipleChoiceAnsNums}`;
      }
    } else {
      return "N/A";
    }
  };

  const renderMatches = (item) => {
    if (item.keywordMatches && item.keywordMatches) {
      if (item.keywordMatches.length > 0) {
        return item.keywordMatches.join(", ");
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };

  const cellStyle = {
    paddingLeft: "15px",
    textAlign: "left",
    color: "#4c7b80",
    fontWeight: "normal",
  };

  const cellStyle3 = {
    paddingLeft: "10px",
    textAlign: "left",
    color: "#4c7b80",
    fontWeight: "normal",
  };

  const exportToExcel = () => {
    let sessionData = content.sessionData;
    const dataToExport = sessionData.map((item) => ({
      Answer: item.userAnswer ? item.userAnswer : "N/A",
      Matches: item.keywordMatches && item.keywordMatches.length > 0 ? item.keywordMatches.join(", ") : "N/A",
      ID: item._id ? item._id.slice(-4) : "N/A",
      "P/F": item.isPassed ? "Pass" : "Fail",
      Score: item.userScore || "N/A",
      "Current Level": item.currentLevel || "0/0+",
      Date: item.date ? new Date(item.date).toLocaleDateString() : "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ItemUserDetails");
    XLSX.writeFile(workbook, "ItemUserDetails.xlsx");
  };

  return (
    <>
      <br /> <br />
      {content && content.testletData && content.testletData.length > 0 && content.testletData[0]._id && (
        <table>
          <tbody>
            <tr>
              <td style={cellStyle}>Test Item</td>
              <td style={cellStyle}>{content.testletData[0].itemId}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Item Type</td>
              <td style={cellStyle}>{content.testletData[0].template}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Question</td>
              <td
                style={cellStyle}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(renderQuestionContent(content.testletData[0], items[2])),
                }}
              ></td>
            </tr>
            <tr>
              <td style={cellStyle}>Key(s)</td>
              <td
                style={cellStyle}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(renderKeyContent(content.testletData[0])),
                }}
              ></td>
            </tr>
            <tr>
              <td style={cellStyle}>Passing Score</td>
              <td style={cellStyle}>{content.testletData[0].passingScore}</td>
            </tr>
          </tbody>
        </table>
      )}
      <br /> <br /> <br />
      {content && content.sessionData && (
        <table>
          <thead>
            <tr>
              <th style={{ width: "35%", paddingLeft: "10px", textAlign: "left" }}>Answer</th>
              <th style={{ width: "20%", paddingLeft: "10px", textAlign: "left" }}>Matches</th>
              <th style={{ width: "10%" }}>ID</th>
              <th style={{ width: "10%" }}>P/F</th>
              <th style={{ width: "5%" }}>Score</th>
              <th style={{ width: "5%" }}>Current Level</th>
              <th style={{ width: "15%" }}>Date</th>
            </tr>
          </thead>
          <tbody>
            {content.sessionData
              .filter((item) => item._id) // Filter out invalid items
              .map((item, index) => (
                <tr key={index}>
                  <td style={cellStyle3}>{item.userAnswer ? item.userAnswer : "N/A"}</td>
                  <td style={cellStyle3}>{renderMatches(item)}</td>
                  <td style={cellStyle3}>{item._id.slice(-4)}</td>
                  <td style={cellStyle3}>{item.isPassed ? "Pass" : "Fail"}</td>
                  <td style={cellStyle3}>{item.userScore}</td>
                  <td style={cellStyle3}>{item.currentLevel || "0/0+"}</td>
                  <td style={cellStyle3}>{item.date ? new Date(item.date).toLocaleDateString() : "N/A"}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      <br />
      <button
        style={{
          fontSize: "13px",
          borderRadius: "10px",
          height: "30px",
          paddingTop: "7px",
          marginRight: "20px",
        }}
        onClick={exportToExcel}
      >
        Export to Excel
      </button>
    </>
  );
};

export default ItemUserDisplayDetail;
