import React, { useRef, useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";
import { ImNewTab } from "react-icons/im";
import { ErrorContext } from "../../../../../context/ErrorProvider";

export default function Audio(props) {
  const [, errorDispatch] = useContext(ErrorContext);

  const sanitizer = dompurify.sanitize;
  const LO = props.LO;
  const lang = props.lang;
  const mainAudio = props.mainAudio;
  const modifiedAudio = props.modifiedAudio;
  const main = "audio/" + lang + "/" + LO + "/" + mainAudio;
  const mod = "audio/" + lang + "/" + LO + "/" + modifiedAudio;
  const questionText = props.questionText;

  // console.log("qe=", JSON.stringify(questionText));

  const audioPlayer = useRef(null);

  const isAudio = (file) => typeof file === "string" && file.endsWith(".mp3");

  return (
    <div>
      {((props.toggleState && modifiedAudio !== null && mod !== null && isAudio(mod)) ||
        (!props.toggleState && mainAudio !== null && main !== null && isAudio(main))) && (
          <AudioPlayer
            id="ODAPlayer2"
            autoPlay={false}
            autoPlayAfterSrcChange={false}
            preload="metadata"
            header={
              <div className="rhap_textCenter">
                Main Passage {props.toggleState ? "(Modified)" : ""}
                <span className="modifiedFlag" />
              </div>
            }
            footer={null}
            customAdditionalControls={[]}
            // customAdditionalControls={[
            //   <button
            //     className={"newTab"}
            //     title="Click here to open this audio file in a separate tab."
            //     onClick={(e) => {
            //       e.preventDefault();
            //       window.open(props.toggleState ? mod : main, "odamp3sideload");
            //     }}
            //   >
            //     <ImNewTab />
            //   </button>
            // ]}
            ref={audioPlayer.main}
            src={props.toggleState ? mod : main}
            customIcons={{
              //   play: <path fill="pink" d="M10 16.5v-9l6 4.5M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2"/>,
              //   rewind: <IoIosRewind />,
              //   forward: <IoMdFastforward />,
              //   volume: <GiSpeaker />,
              //
              play: <IoPlay />,
              pause: <IoPause />,
              rewind: <IoIosRewind />,
              forward: <IoMdFastforward />,
              volume: <GiSpeaker />
            }}
            onError={(e) => {
              if (typeof e.target.error !== "undefined") {
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: 998,
                    errorUserMessage: "AudioPlayer General Error",
                    errorDevData: e.target.error.message.toString()
                  }
                });
              }
            }}
            onPlayError={(e) => {
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: 999,
                  errorUserMessage: "AudioPlayer OnPlay Error",
                  errorDevData: e.toString()
                }
              });
            }}
          />
        )}
      <h3>
        <div className="audioQ">
          {/* Display the first item, which can be either text or audio */}
          {isAudio(questionText[0]) ? (
            <AudioPlayer
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              preload="metadata"
              header={null}
              footer={null}
              hasDefaultKeyBindings={false}
              customAdditionalControls={[]}
              customVolumeControls={[]}
              ref={audioPlayer.inline0}
              src={"audio/" + lang + "/" + LO + "/" + questionText[0]}
              showJumpControls={false}
              showDownloadProgress={false}
              showFilledProgress={false}
              customIcons={{
                play: <IoPlay />,
                pause: <IoPause />,
                rewind: <IoIosRewind />,
                forward: <IoMdFastforward />,
                volume: <GiSpeaker />
              }}
              onError={(e) => {
                if (typeof e.target.error !== "undefined") {
                  errorDispatch({
                    type: "UPDATE_ERROR_STATE",
                    payload: {
                      errorStatusCode: 998,
                      errorUserMessage: "AudioPlayer General Error",
                      errorDevData: e.target.error.message.toString()
                    }
                  });
                }
              }}
              onPlayError={(e) => {
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: 999,
                    errorUserMessage: "AudioPlayer OnPlay Error",
                    errorDevData: e.toString()
                  }
                });
              }}
            />
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(questionText[0])
              }}
            />
          )}

          {/* Display the second item if it exists, which can be either text or audio */}
          {(questionText.length === 2 || questionText.length === 3) && (
            <>
              {isAudio(questionText[1]) ? (
                <AudioPlayer
                  preload="metadata"
                  autoPlay={false}
                  autoPlayAfterSrcChange={false}
                  header={null}
                  footer={null}
                  hasDefaultKeyBindings={false}
                  customAdditionalControls={[]}
                  customVolumeControls={[]}
                  ref={audioPlayer.inline1}
                  src={"audio/" + lang + "/" + LO + "/" + questionText[1]}
                  showJumpControls={false}
                  showDownloadProgress={false}
                  showFilledProgress={false}
                  customIcons={{
                    play: <IoPlay />,
                    pause: <IoPause />,
                    rewind: <IoIosRewind />,
                    forward: <IoMdFastforward />,
                    volume: <GiSpeaker />
                  }}
                  onError={(e) => {
                    if (typeof e.target.error !== "undefined") {
                      errorDispatch({
                        type: "UPDATE_ERROR_STATE",
                        payload: {
                          errorStatusCode: 998,
                          errorUserMessage: "AudioPlayer General Error",
                          errorDevData: e.target.error.message.toString()
                        }
                      });
                    }
                  }}
                  onPlayError={(e) => {
                    errorDispatch({
                      type: "UPDATE_ERROR_STATE",
                      payload: {
                        errorStatusCode: 999,
                        errorUserMessage: "AudioPlayer OnPlay Error",
                        errorDevData: e.toString()
                      }
                    });
                  }}
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(questionText[1])
                  }}
                />
              )}
            </>
          )}

          {/* Display the third item if it exists, which can only be text */}
          {questionText.length === 3 && !isAudio(questionText[2]) && (
            <span
              key={questionText[2]}
              dangerouslySetInnerHTML={{
                __html: sanitizer(questionText[2])
              }}
            />
          )}
        </div>
      </h3>
    </div>
  );
}
