import React, { useRef, useState, useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";
import { ImNewTab } from "react-icons/im";
import { ErrorContext } from "../../../../../context/ErrorProvider";

export default function AnswerOption(props) {
  const [, errorDispatch] = useContext(ErrorContext);

  const sanitizer = dompurify.sanitize;
  const LO = props.LO;
  const lang = props.lang;
  const actID = props.actID + "_0";
  const audioOption = props.question;
  const len = audioOption.length;
  const audioPlayerAns = useRef(null);

  const numberLetter = ["One", "Two", "Three", "Four", "Five"];

  let mp3 = [];
  for (let i = 0; i < len - 1; i++) {
    mp3.push("/audio/" + lang + "/" + LO + "/" + audioOption[i].value.replace(/\s/g, "_"));
  }

  const isAudio = (file) => typeof file === "string" && file.endsWith(".mp3");

  const createAudioRadioButton = () => {
    let radioButton = [];
    for (let i = 0; i < len; i++) {
      if (i !== len - 1)
        if (mp3[i] !== null && isAudio(mp3[i])) {
          radioButton.push(
            <div className="answerBlock" key={i}>
              <label className="answer" key={i}>
                {/* <span className="inlinePlay paused" file={mp3[i]}>
                Audio {numberLetter[i]}
                <span className="modifiedFlag" />
              </span> */}
                <input key={i} type="radio" name="radio" id={actID} value={i} onChange={props.onAnswerSelected} />
                <span className="radio" />
              </label>
              <div className="audioQ forceInlineBlock buttonWrapper">
                <AudioPlayer
                  className="mainButton"
                  autoPlay={false}
                  autoPlayAfterSrcChange={false}
                  preload="metadata"
                  // header={props.toggleState ? "(modified)" : null}
                  header={"Audio " + numberLetter[i]}
                  footer={null}
                  hasDefaultKeyBindings={false}
                  customAdditionalControls={[]}
                  // customAdditionalControls={[
                  //   <button
                  //     className={"newTabInline"}
                  //     title="Click here to open this audio file in a separate tab."
                  //     onClick={(e) => {
                  //       e.preventDefault();
                  //       window.open(mp3[i], "odamp3sideload");
                  //     }}
                  //   >
                  //     <ImNewTab />
                  //   </button>
                  // ]}
                  customVolumeControls={[]}
                  ref={audioPlayerAns.inline0}
                  src={mp3[i]}
                  showJumpControls={false}
                  showDownloadProgress={false}
                  showFilledProgress={false}
                  customIcons={{
                    play: <IoPlay />,
                    pause: <IoPause />,
                    rewind: <IoIosRewind />,
                    forward: <IoMdFastforward />,
                    volume: <GiSpeaker />
                  }}
                  onError={(e) => {
                    if (typeof e.target.error !== "undefined") {
                      errorDispatch({
                        type: "UPDATE_ERROR_STATE",
                        payload: {
                          errorStatusCode: 998,
                          errorUserMessage: "AudioPlayer General Error",
                          errorDevData: e.target.error.message.toString()
                        }
                      });
                    }
                  }}
                  onPlayError={(e) => {
                    errorDispatch({
                      type: "UPDATE_ERROR_STATE",
                      payload: {
                        errorStatusCode: 999,
                        errorUserMessage: "AudioPlayer OnPlay Error",
                        errorDevData: e.toString()
                      }
                    });
                  }}
                />
                {/* <button
                    className={"newTabInline drawerButton"}
                    title="Click here to open this audio file in a separate tab."
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(mp3[i], "odamp3sideload");
                    }}
                  >
                    <ImNewTab />
                  </button> */}
              </div>
            </div>
          );
        } else
          radioButton.push(
            <div className="answerBlock" key={i}>
              <label className="answer" key={i}>
                {" "}
                {audioOption[i].value}
                <input key={i} type="radio" name="radio" id={actID} value={i} onChange={props.onAnswerSelected} />
                <span className="radio" />
              </label>
            </div>
          );
    }
    return radioButton;
  };
  return createAudioRadioButton();
}
