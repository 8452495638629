import React, { useRef, useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";
import { ErrorContext } from "../../context/ErrorProvider";

export default function Audio(props) {
  const [, errorDispatch] = useContext(ErrorContext);

  const sanitizer = dompurify.sanitize;
  const lang = props.lang;
  const LO = props.actID;
  let SQID = parseInt(props.audSequence);
  let title = "Passage" + SQID;
  const audFN1 = "audio/" + lang + "/" + LO + "/" + props.audioFN1;
  const audFN2 = "audio/" + lang + "/" + LO + "/" + props.audioFN2;
  const audFN3 = "audio/" + lang + "/" + LO + "/" + props.audioFN3;
  const audioPlayer = useRef(null);

  const instruction = (
    <div className="audPassInst" dangerouslySetInnerHTML={{ __html: sanitizer(props.instruction) }} />
  );

  const isAudio = (file) => typeof file === "string" && file.endsWith(".mp3");

  return (
    <div>
      {instruction}
      {(SQID === 1 ? audFN1 : SQID === 2 ? audFN2 : SQID === 3 ? audFN3 : audFN1) !== null &&
        isAudio(SQID === 1 ? audFN1 : SQID === 2 ? audFN2 : SQID === 3 ? audFN3 : audFN1) && (
          <AudioPlayer
            id="ODAPlayer2"
            preload="metadata"
            autoPlay={false}
            autoPlayAfterSrcChange={true}
            header={
              <div className="rhap_textCenter">
                {title}
                <span className="modifiedFlag" />
              </div>
            }
            onError={(e) => {
              if (typeof e.target.error !== "undefined") {
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: 998,
                    errorUserMessage: "AudioPlayer General Error",
                    errorDevData: e.target.error.message.toString()
                  }
                });
              }
            }}
            onPlayError={(e) => {
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: 999,
                  errorUserMessage: "AudioPlayer OnPlay Error",
                  errorDevData: e.toString()
                }
              });
            }}
            footer={null}
            customAdditionalControls={[]}
            ref={audioPlayer.main}
            src={SQID === 1 ? audFN1 : SQID === 2 ? audFN2 : SQID === 3 ? audFN3 : audFN1}
            customIcons={{
              play: <IoPlay />,
              pause: <IoPause />,
              rewind: <IoIosRewind />,
              forward: <IoMdFastforward />,
              volume: <GiSpeaker />
            }}
          />
        )}
    </div>
  );
}
