import React, { useRef, useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";
import { ErrorContext } from "../../../../../context/ErrorProvider";

export default function Audio(props) {
  const [, errorDispatch] = useContext(ErrorContext);

  const sanitizer = dompurify.sanitize;
  const LO = props.LO;
  const lang = props.lang;
  const mainAudio = props.mainAudio;
  const modifiedAudio = props.modifiedAudio;
  const itemInContextAudio = props.itemInContextAudio;
  const main = "audio/" + lang + "/" + LO + "/" + mainAudio;
  const mod = "audio/" + lang + "/" + LO + "/" + modifiedAudio;
  const context = "audio/" + lang + "/" + LO + "/" + itemInContextAudio;
  const questionText = props.questionText;
  const audioPlayer = useRef(null);

  const isAudio = (file) => typeof file === "string" && file.endsWith(".mp3");

  return (
    <div>
      <h2>Listen to the passage:</h2>
      {((props.toggleState && modifiedAudio !== null && mod !== null && isAudio(mod)) ||
        (!props.toggleState && mainAudio !== null && main !== null && isAudio(main))) && (
        <AudioPlayer
          id="ODAPlayer2"
          autoPlay={false}
          autoPlayAfterSrcChange={false}
          preload="metadata"
          header={
            <div className="rhap_textCenter">
              Main Passage {props.toggleState ? "(Modified)" : ""}
              <span className="modifiedFlag" />
            </div>
          }
          footer={null}
          customAdditionalControls={[]}
          ref={audioPlayer.main}
          src={props.toggleState ? mod : main}
          customIcons={{
            play: <IoPlay />,
            pause: <IoPause />,
            rewind: <IoIosRewind />,
            forward: <IoMdFastforward />,
            volume: <GiSpeaker />
          }}
          onError={(e) => {
            if (typeof e.target.error !== "undefined") {
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: 998,
                  errorUserMessage: "AudioPlayer General Error",
                  errorDevData: e.target.error.message.toString()
                }
              });
            }
          }}
          onPlayError={(e) => {
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: 999,
                errorUserMessage: "AudioPlayer OnPlay Error",
                errorDevData: e.toString()
              }
            });
          }}
        />
      )}
      <h3>
        {props.itemInContextAudio !== null && context !== null && isAudio(context) && (
          <div className="audioQ">
            <AudioPlayer
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              preload="metadata"
              header={"Item In Context"}
              footer={null}
              hasDefaultKeyBindings={false}
              customAdditionalControls={[]}
              customVolumeControls={[]}
              ref={audioPlayer.inline0}
              src={context}
              showJumpControls={false}
              showDownloadProgress={false}
              showFilledProgress={false}
              customIcons={{
                play: <IoPlay />,
                pause: <IoPause />,
                rewind: <IoIosRewind />,
                forward: <IoMdFastforward />,
                volume: <GiSpeaker />
              }}
              onError={(e) => {
                if (typeof e.target.error !== "undefined") {
                  errorDispatch({
                    type: "UPDATE_ERROR_STATE",
                    payload: {
                      errorStatusCode: 998,
                      errorUserMessage: "AudioPlayer General Error",
                      errorDevData: e.target.error.message.toString()
                    }
                  });
                }
              }}
              onPlayError={(e) => {
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: 999,
                    errorUserMessage: "AudioPlayer OnPlay Error",
                    errorDevData: e.toString()
                  }
                });
              }}
            />
          </div>
        )} 
        <div className="audioQ">
          <span
            key={questionText[0]}
            dangerouslySetInnerHTML={{
              __html: sanitizer(questionText[0])
            }}
          />
          {questionText[1] !== null &&
            isAudio(questionText[1]) &&
            (questionText.length === 2 || questionText.length === 3) && (
              <>
                <AudioPlayer
                  autoPlay={false}
                  autoPlayAfterSrcChange={false}
                  preload="metadata"
                  header={null}
                  footer={null}
                  hasDefaultKeyBindings={false}
                  customAdditionalControls={[]}
                  customVolumeControls={[]}
                  ref={audioPlayer.inline0}
                  src={"audio/" + lang + "/" + LO + "/" + questionText[1]}
                  showJumpControls={false}
                  showDownloadProgress={false}
                  showFilledProgress={false}
                  customIcons={{
                    play: <IoPlay />,
                    pause: <IoPause />,
                    rewind: <IoIosRewind />,
                    forward: <IoMdFastforward />,
                    volume: <GiSpeaker />
                  }}
                  onError={(e) => {
                    if (typeof e.target.error !== "undefined") {
                      errorDispatch({
                        type: "UPDATE_ERROR_STATE",
                        payload: {
                          errorStatusCode: 998,
                          errorUserMessage: "AudioPlayer General Error",
                          errorDevData: e.target.error.message.toString()
                        }
                      });
                    }
                  }}
                  onPlayError={(e) => {
                    errorDispatch({
                      type: "UPDATE_ERROR_STATE",
                      payload: {
                        errorStatusCode: 999,
                        errorUserMessage: "AudioPlayer OnPlay Error",
                        errorDevData: e.toString()
                      }
                    });
                  }}
                />
                {questionText.length === 3 && (
                  <span
                    key={questionText[2]}
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(questionText[2])
                    }}
                  />
                )}
              </>
            )}
        </div>
      </h3>
    </div>
  );
}
