import React, { useState, useEffect } from "react";
import axios from "axios";
import dompurify from "dompurify";

const UserAnswerSearchPanelDisplayItemTrack = () => {
  console.log("Component Mounted");
  const sanitizer = dompurify.sanitize;
  const [items, setItems] = useState([]);
  const [content, setContent] = useState([]);

  const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredContent = content.filter((item) => {
    if (filter === "correct") return item.isPassed;
    if (filter === "incorrect") return !item.isPassed;
    return true;
  });

  useEffect(() => {
    console.log("Fetching stored items from localStorage");
    const storedItems = localStorage.getItem("trackItems");
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      setItems(parsedItems);
      console.log("Parsed Items:", parsedItems);
    }
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      const [sessionId, modality] = items;

      axios
        .get(`/api/adminReport/itemTracker?mod=${modality}&id=${sessionId}`)
        .then((res) => {
          //issue #469 - validate the incoming data due to data parsing errors causing 404s for SMEs
          const incomingData = JSON.parse(decodeURIComponent(res.data));
          if (
            typeof incomingData === "object" &&
            incomingData.length > 0
            // incomingData.hasOwnProperty("sessionData") &&
            // incomingData.sessionData.length > 0
          ) {
            setContent(incomingData);
          } else {
            console.log("API data error.");
          }
        })
        .catch((error) => {
          console.error("API request failed:", error);
        });
    }
  }, [items]);

  //redundant with #469 fix above
  // const isURLEncoded = (str) => {
  //   try {
  //     return str !== decodeURIComponent(str);
  //   } catch (e) {
  //     return false;
  //   }
  // };

  // const safeDecodeURIComponent = (string) => {
  //   try {
  //     return isURLEncoded(string) ? decodeURIComponent(string) : string;
  //   } catch (e) {
  //     console.error("Decoding error:", e);
  //     return string; // Fallback to the original string
  //   }
  // };

  const radioButtonStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f4f4f4",
  };

  const filterBoxStyle = {
    marginBottom: "20px",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "space-around",
    width: "400px",
  };

  const h3Style = {
    marginTop: "0",
  };

  const pStyle = {
    margin: "5px 0",
  };

  const ContentBlock = ({ item }) => {
    const contentBlockStyle = {
      backgroundColor: item.isPassed ? "#d4edda" : "#f8d7da",
      border: "1px solid #ddd",
      borderRadius: "5px",
      padding: "20px",
      margin: "10px",
      width: "450px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    };

    return (
      <div style={contentBlockStyle}>
        <h4 style={h3Style}>Item ID: {item.itemId}</h4>
        <p style={pStyle}>
          <strong>Template:</strong> {item.template}
        </p>
        <p style={pStyle}>
          <strong>Question:</strong>

          <span
            dangerouslySetInnerHTML={{
              __html: sanitizer(item.questionText),
            }}
          ></span>
        </p>
        <p style={pStyle}>
          <strong>User Answer:</strong>{" "}
          {/* {safeDecodeURIComponent(item.userAnswer)} */}
          {item.userAnswer}
        </p>
        <p style={pStyle}>
          <strong>Keywords:</strong>{" "}
          {item.keywords
            .map((keyword) => keyword.matchingPatterns.join(", "))
            .join("; ")}
        </p>
        <p style={pStyle}>
          <strong>Keyword Matches:</strong> {item.keywordMatches.join(", ")}
        </p>

        <p style={pStyle}>
          <strong>Is Passed:</strong> {item.isPassed ? "Yes" : "No"}
        </p>
        <p style={pStyle}>
          <strong>User Score:</strong> {item.userScore}
        </p>
        <p style={pStyle}>
          <strong>Passing Score:</strong> {item.passingScore}
        </p>
      </div>
    );
  };
  return (
    <div style={radioButtonStyle}>
      <div style={filterBoxStyle}>
        <label>
          <input
            type="radio"
            value="all"
            checked={filter === "all"}
            onChange={handleFilterChange}
          />
          All Items
        </label>
        <label>
          <input
            type="radio"
            value="correct"
            checked={filter === "correct"}
            onChange={handleFilterChange}
          />
          Correct Only
        </label>
        <label>
          <input
            type="radio"
            value="incorrect"
            checked={filter === "incorrect"}
            onChange={handleFilterChange}
          />
          Incorrect Only
        </label>
      </div>
      <p>{filteredContent.length} items found</p>
      <div>
        {filteredContent.map((item, index) => (
          <ContentBlock key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default UserAnswerSearchPanelDisplayItemTrack;
