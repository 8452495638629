import React, { useRef, useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";
import { ErrorContext } from "../../../../../context/ErrorProvider";

export default function Audio(props) {
  const [, errorDispatch] = useContext(ErrorContext);
  const sanitizer = dompurify.sanitize;
  const LO = props.LO;
  const lang = props.lang;
  let SQID = parseInt(props.audSequence);
  const mainAudio = "audio/" + lang + "/" + LO + "/" + props.mainAudio;
  const itemInContextAudio = "audio/" + lang + "/" + LO + "/" + props.itemInContextAudio;
  const audioClip = props.audioClip;
  const audClipLen = audioClip.length;
  let audioItem1 = "";
  let audioItem2 = "";
  let title = "";
  let audioMain = "";
  const audioPlayer = useRef(null);

  if (props.itemInContextAudio !== null && props.template === "AMC2") {
    title = "Item In Context";
    audioMain = itemInContextAudio;
  } else if (props.mainAudio === null && audClipLen !== 0) {
    audioItem1 = "audio/" + lang + "/" + LO + "/" + audioClip[0].substr(5);
    audioItem2 = "audio/" + lang + "/" + LO + "/" + audioClip[1].substr(5);
    title = "Audio Passage " + SQID;
    audioMain = audioItem1;
  } else if (props.mainAudio === null && audClipLen === 0) {
    title = "Item In Context";
    audioMain = itemInContextAudio;
  } else {
    title = "Main Passage";
    audioMain = mainAudio;
  }

  // console.log(audioMain);

  const questionText = props.questionText;
  const len = questionText.length;

  const isAudio = (file) => typeof file === "string" && file.endsWith(".mp3");

  return (
    <div>
      <h2>Listen to the passage:</h2>
      {(props.mainAudio === null && audClipLen !== 0 && SQID === 2 ? audioItem2 : audioMain) !== null &&
        isAudio(props.mainAudio === null && audClipLen !== 0 && SQID === 2 ? audioItem2 : audioMain) && (
          <AudioPlayer
            id="ODAPlayer2"
            preload="metadata"
            autoPlay={false}
            autoPlayAfterSrcChange={false}
            header={
              <div className="rhap_textCenter">
                {title}
                <span className="modifiedFlag" />
              </div>
            }
            footer={null}
            customAdditionalControls={[]}
            ref={audioPlayer.main}
            src={props.mainAudio === null && audClipLen !== 0 && SQID === 2 ? audioItem2 : audioMain}
            customIcons={{
              play: <IoPlay />,
              pause: <IoPause />,
              rewind: <IoIosRewind />,
              forward: <IoMdFastforward />,
              volume: <GiSpeaker />
            }}
            onError={(e) => {
              if (typeof e.target.error !== "undefined") {
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: 998,
                    errorUserMessage: "AudioPlayer General Error",
                    errorDevData: e.target.error.message.toString()
                  }
                });
              }
            }}
            onPlayError={(e) => {
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: 999,
                  errorUserMessage: "AudioPlayer OnPlay Error",
                  errorDevData: e.toString()
                }
              });
            }}
          />
        )}
      <h3>
        <div className="audioQ">
          <span
            key={questionText[0]}
            dangerouslySetInnerHTML={{
              __html: sanitizer(questionText[0])
            }}
          />
          {"audio/" + lang + "/" + LO + "/" + questionText[1] !== null &&
            questionText[1] !== null &&
            isAudio(questionText[1]) &&
            (questionText.length === 2 || questionText.length === 3) && (
              <>
                <AudioPlayer
                  autoPlay={false}
                  autoPlayAfterSrcChange={false}
                  preload="metadata"
                  header={null}
                  footer={null}
                  hasDefaultKeyBindings={false}
                  customAdditionalControls={[]}
                  customVolumeControls={[]}
                  ref={audioPlayer.inline0}
                  src={"audio/" + lang + "/" + LO + "/" + questionText[1]}
                  showJumpControls={false}
                  showDownloadProgress={false}
                  showFilledProgress={false}
                  customIcons={{
                    play: <IoPlay />,
                    pause: <IoPause />,
                    rewind: <IoIosRewind />,
                    forward: <IoMdFastforward />,
                    volume: <GiSpeaker />
                  }}
                  onError={(e) => {
                    if (typeof e.target.error !== "undefined") {
                      errorDispatch({
                        type: "UPDATE_ERROR_STATE",
                        payload: {
                          errorStatusCode: 998,
                          errorUserMessage: "AudioPlayer General Error",
                          errorDevData: e.target.error.message.toString()
                        }
                      });
                    }
                  }}
                  onPlayError={(e) => {
                    errorDispatch({
                      type: "UPDATE_ERROR_STATE",
                      payload: {
                        errorStatusCode: 999,
                        errorUserMessage: "AudioPlayer OnPlay Error",
                        errorDevData: e.toString()
                      }
                    });
                  }}
                />
                {questionText.length === 3 && (
                  <span
                    key={questionText[2]}
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(questionText[2])
                    }}
                  />
                )}
              </>
            )}
        </div>
      </h3>
    </div>
  );
}
