import React, { useRef, useState, useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";
import { ErrorContext } from "../../../../../context/ErrorProvider";

export default function AnswerOption(props) {
  const [, errorDispatch] = useContext(ErrorContext);

  const LO = props.LO;
  const lang = props.lang;
  const actID = props.actID;
  const numberLetter = ["one", "two", "three", "four", "five"];
  const ddList = props.dropdownOptions;
  const selValue = props.value;
  const audioPlayerAns = useRef(null);

  let audioQuestion = "";
  let dropdown = "";

  dropdown = ddList[0].map((item, index) => (
    <option key={item} value={index}>
      {item}
    </option>
  ));

  const isAudio = (file) => typeof file === "string" && file.endsWith(".mp3");

  const createAnswerField = () => {
    const len = props.question.length;
    let items = [];
    for (let i = 0; i < len; i++) {
      audioQuestion = props.question[i];
      audioQuestion = "audio/" + lang + "/" + LO + "/" + audioQuestion.value.replace(/\s/g, "_");
      if (audioQuestion !== null && isAudio(audioQuestion)) {
        items.push(
          <div className="categorizeAnswer" key={i}>
            <p className="question">
              {/* <span className="inlinePlay paused" file={audioQuestion}>
              Audio {numberLetter[i]}
            </span> */}
              <div className="audioQ ">
                <AudioPlayer
                  preload="metadata"
                  autoPlay={false}
                  autoPlayAfterSrcChange={false}
                  // header={props.toggleState ? "(modified)" : null}
                  header={"Audio " + numberLetter[i]}
                  footer={null}
                  hasDefaultKeyBindings={false}
                  customAdditionalControls={[]}
                  customVolumeControls={[]}
                  ref={audioPlayerAns.inline0}
                  src={audioQuestion}
                  showJumpControls={false}
                  showDownloadProgress={false}
                  showFilledProgress={false}
                  customIcons={{
                    play: <IoPlay />,
                    pause: <IoPause />,
                    rewind: <IoIosRewind />,
                    forward: <IoMdFastforward />,
                    volume: <GiSpeaker />
                  }}
                  onError={(e) => {
                    if (typeof e.target.error !== "undefined") {
                      errorDispatch({
                        type: "UPDATE_ERROR_STATE",
                        payload: {
                          errorStatusCode: 998,
                          errorUserMessage: "AudioPlayer General Error",
                          errorDevData: e.target.error.message.toString()
                        }
                      });
                    }
                  }}
                  onPlayError={(e) => {
                    errorDispatch({
                      type: "UPDATE_ERROR_STATE",
                      payload: {
                        errorStatusCode: 999,
                        errorUserMessage: "AudioPlayer OnPlay Error",
                        errorDevData: e.toString()
                      }
                    });
                  }}
                />
              </div>
            </p>
            <select name="" defaultValue={selValue} id={actID + "_" + i} onChange={props.onAnswerSelected}>
              <option value={selValue} disabled>
                Select
              </option>
              {dropdown}
            </select>
          </div>
        );
      }
    }
    return items;
  };
  return <div className="categorizeAnswer">{createAnswerField()}</div>;
}
