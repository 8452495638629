import React, { useRef, useState, useEffect, useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";
import { ErrorContext } from "../../../../../context/ErrorProvider";

export default function PassageAnswerOption(props) {
  const [, errorDispatch] = useContext(ErrorContext);
  const lang = props.lang;
  const LO = props.actID;
  const num = props.optionNumber;
  const answerFieldsID = props.answerFieldsID;
  const testitem = props.testitem;
  const audioPlayerAns = useRef(null);
  const numberLetter = ["One", "Two", "Three", "Four", "Five"];
  const number = num + 1;
  const passageTitle = "Passage " + number;
  const passageLen = props.optionLen;

  let clickCounter = props.clickCounter;

  useEffect(() => {
    if (clickCounter === 0) {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "none";
      });
    } else {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "none";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
    }
  }, [clickCounter]);

  const createAudioPassage = () => {
    let main = [];
    let audioTitle = "Audio Passage " + number;
    main.push(
      <div key={number} id={number} onClick={props.onPlayMainAudio}>
        <div className="audPassage">{audioTitle}</div>
      </div>
    );
    return main;
  };

  const questionText = <span>Assessment Item {number}</span>;

  const isAudio = (file) => typeof file === "string" && file.endsWith(".mp3");

  const questionItem = props.optionPassage.map((options, index) => {
    let items = [];
    let mp3 = options.substr(-4);
    if (mp3 === ".mp3" && index === passageLen - 1) {
      let fn = "audio/" + lang + "/" + LO + "/" + options;
      if (fn !== null && isAudio(fn)) {
        items.push(
          <div className="audioQ ">
            <AudioPlayer
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              preload="metadata"
              header={questionText}
              footer={null}
              hasDefaultKeyBindings={false}
              customAdditionalControls={[]}
              customVolumeControls={[]}
              ref={audioPlayerAns.inline0}
              src={fn}
              showJumpControls={false}
              showDownloadProgress={false}
              showFilledProgress={false}
              customIcons={{
                play: <IoPlay />,
                pause: <IoPause />,
                rewind: <IoIosRewind />,
                forward: <IoMdFastforward />,
                volume: <GiSpeaker />
              }}
              onError={(e) => {
                if (typeof e.target.error !== "undefined") {
                  errorDispatch({
                    type: "UPDATE_ERROR_STATE",
                    payload: {
                      errorStatusCode: 998,
                      errorUserMessage: "AudioPlayer General Error",
                      errorDevData: e.target.error.message.toString()
                    }
                  });
                }
              }}
              onPlayError={(e) => {
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: 999,
                    errorUserMessage: "AudioPlayer OnPlay Error",
                    errorDevData: e.toString()
                  }
                });
              }}
            />
          </div>
        );
      }
    }
    return items;
  });

  const answerContent = props.optionChoice;
  const contNum = answerContent.length;
  const createText = () => {
    let txt = [];
    for (let i = 0; i < contNum; i++) {
      txt.push(answerContent[i].value);
    }
    return txt;
  };

  const itemTxt = createText();
  const radioID_fst = answerFieldsID + "_0";
  const radioID_snd = answerFieldsID + "_1";
  const radioGroup1 = "radioGroup_0" + num;
  const radioGroup2 = "radioGroup_1" + num;

  const createRadioButton = () => {
    let radioButton = [];
    for (let i = 0; i < contNum; i++) {
      const mykey = props.actID + i;
      const mykey2 = props.actID + i + 0;
      const audioItem = "audio/" + lang + "/" + LO + "/" + itemTxt[i];
      const lastQuestion = itemTxt[i];

      if (i !== contNum - 1) {
        if (audioItem !== null && isAudio(audioItem)) {
          radioButton.push(
            <div className="fstAttempt" key={i}>
              <div className="answerBlock" key={mykey}>
                <label className="answer">
                  {/* <span className="inlinePlay paused" file={audioItem}>
                  Audio {numberLetter[i]}
                  <span className="modifiedFlag" />
                </span> */}
                  <input
                    type="radio"
                    name={radioGroup1}
                    data-testitem={testitem}
                    placeholder={radioID_fst}
                    id={radioID_fst + i}
                    value={i}
                    pattern={answerFieldsID}
                    onChange={props.onAnswerSelected}
                  />
                  <span className="radio" />
                </label>
                <div className="audioQ forceInlineBlock">
                  <AudioPlayer
                    autoPlay={false}
                    autoPlayAfterSrcChange={false}
                    preload="metadata"
                    // header={props.toggleState ? "(modified)" : null}
                    header={"Audio " + numberLetter[i]}
                    footer={null}
                    hasDefaultKeyBindings={false}
                    customAdditionalControls={[]}
                    customVolumeControls={[]}
                    ref={audioPlayerAns["inline-" + i]}
                    src={audioItem}
                    showJumpControls={false}
                    showDownloadProgress={false}
                    showFilledProgress={false}
                    customIcons={{
                      play: <IoPlay />,
                      pause: <IoPause />,
                      rewind: <IoIosRewind />,
                      forward: <IoMdFastforward />,
                      volume: <GiSpeaker />
                    }}
                    onError={(e) => {
                      if (typeof e.target.error !== "undefined") {
                        errorDispatch({
                          type: "UPDATE_ERROR_STATE",
                          payload: {
                            errorStatusCode: 998,
                            errorUserMessage: "AudioPlayer General Error",
                            errorDevData: e.target.error.message.toString()
                          }
                        });
                      }
                    }}
                    onPlayError={(e) => {
                      errorDispatch({
                        type: "UPDATE_ERROR_STATE",
                        payload: {
                          errorStatusCode: 999,
                          errorUserMessage: "AudioPlayer OnPlay Error",
                          errorDevData: e.toString()
                        }
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }
        if (audioItem !== null && isAudio(audioItem)) {
          radioButton.push(
            <div className="sndAttempt" key={i + 100}>
              <div className="answerBlock" key={mykey2}>
                <label className="answer">
                  {/* <span className="inlinePlay paused" file={audioItem}>
                  Audio {numberLetter[i]}
                  <span className="modifiedFlag" />
                </span> */}
                  <input
                    type="radio"
                    name={radioGroup2}
                    data-testitem={testitem}
                    placeholder={radioID_snd}
                    id={radioID_snd + i}
                    value={i}
                    pattern={answerFieldsID}
                    onChange={props.onAnswerSelectedMod}
                  />
                  <span className="radio" />
                </label>
                <div className="audioQ forceInlineBlock">
                  <AudioPlayer
                    autoPlay={false}
                    autoPlayAfterSrcChange={false}
                    preload="metadata"
                    // header={props.toggleState ? "(modified)" : null}
                    header={"Audio " + numberLetter[i]}
                    footer={null}
                    hasDefaultKeyBindings={false}
                    customAdditionalControls={[]}
                    customVolumeControls={[]}
                    ref={audioPlayerAns["inline-" + i]}
                    src={audioItem}
                    showJumpControls={false}
                    showDownloadProgress={false}
                    showFilledProgress={false}
                    customIcons={{
                      play: <IoPlay />,
                      pause: <IoPause />,
                      rewind: <IoIosRewind />,
                      forward: <IoMdFastforward />,
                      volume: <GiSpeaker />
                    }}
                    onError={(e) => {
                      if (typeof e.target.error !== "undefined") {
                        errorDispatch({
                          type: "UPDATE_ERROR_STATE",
                          payload: {
                            errorStatusCode: 998,
                            errorUserMessage: "AudioPlayer General Error",
                            errorDevData: e.target.error.message.toString()
                          }
                        });
                      }
                    }}
                    onPlayError={(e) => {
                      errorDispatch({
                        type: "UPDATE_ERROR_STATE",
                        payload: {
                          errorStatusCode: 999,
                          errorUserMessage: "AudioPlayer OnPlay Error",
                          errorDevData: e.toString()
                        }
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }
      } else {
        radioButton.push(
          <div className="fstAttempt" key={i}>
            <div className="answerBlock" key={mykey}>
              <label className="answer">
                {lastQuestion}
                <input
                  type="radio"
                  name={radioGroup1}
                  data-testitem={testitem}
                  placeholder={radioID_fst}
                  id={radioID_fst + i}
                  value={i}
                  pattern={answerFieldsID}
                  onChange={props.onAnswerSelected}
                />
                <span className="radio" />
              </label>
            </div>
          </div>
        );
        radioButton.push(
          <div className="sndAttempt" key={i + 100}>
            <div className="answerBlock" key={mykey2}>
              <label className="answer">
                {lastQuestion}
                <input
                  type="radio"
                  name={radioGroup2}
                  placeholder={radioID_snd}
                  data-testitem={testitem}
                  id={radioID_snd + i}
                  value={i}
                  pattern={answerFieldsID}
                  onChange={props.onAnswerSelectedMod}
                />
                <span className="radio" />
              </label>
            </div>
          </div>
        );
      }
    }
    return radioButton;
  };

  const buildup = (
    <div>
      <div>
        <div className="answerSection noSelect">
          <div className="gridContainer">{createRadioButton()}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <hr />
      <h2>{passageTitle}</h2>
      <h3>
        <div className="GRLC_content">
          <div className="GRLC_item">{createAudioPassage()}</div>
          <div className="GRLC_item secondItem">{questionItem}</div>
        </div>
      </h3>
      {buildup}
    </div>
  );
}
