import React, { useRef, useState, useEffect, useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";
import { ErrorContext } from "../../../../../context/ErrorProvider";

export default function PassageAnswerOption(props, index) {
  const [, errorDispatch] = useContext(ErrorContext);

  const lang = props.lang;
  const LO = props.actID;
  const num = props.optionNumber;
  const len = num.length;
  const actID = props.actID;
  const value = props.value;
  const audioPlayerAns = useRef(null);

  const testitem = props.testitem;
  const answerFieldsID = props.answerFieldsID;

  let clickCounter = props.clickCounter;

  useEffect(() => {
    if (clickCounter === 0) {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "none";
      });
    } else {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "none";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
    }
  }, [clickCounter]);

  const numberLetter = ["one", "two", "three", "four", "five"];

  const ddList = props.ddList;
  const queWord = props.optionQuestion;

  const queAudioFile = queWord.map((item, index) => {
    return "audio/" + lang + "/" + LO + "/" + item.value;
  });

  let dropdown = ddList.map((item, index) => (
    <option key={item} value={index}>
      {item}
    </option>
  ));

  const isAudio = (file) => typeof file === "string" && file.endsWith(".mp3");

  const createAnswerField = () => {
    let items = [];
    for (let i = 0; i < len; i++) {
      let answerFieldID = answerFieldsID[i];
      if (queAudioFile[i] !== null && isAudio(queAudioFile[i])) {
        items.push(
          <div className="categorizeAnswer" key={i}>
            <div className="fstAttempt">
              <p className="question">
                {/* <span className="inlinePlay paused" file={queAudioFile[i]}>
                Audio {numberLetter[num]}
              </span> */}
                <div className="audioQ ">
                  <AudioPlayer
                    preload="metadata"
                    autoPlay={false}
                    autoPlayAfterSrcChange={false}
                    // header={props.toggleState ? "(modified)" : null}
                    header={"Audio " + numberLetter[i]}
                    footer={null}
                    hasDefaultKeyBindings={false}
                    customAdditionalControls={[]}
                    customVolumeControls={[]}
                    ref={audioPlayerAns["inline-" + index]}
                    src={queAudioFile[i]}
                    showJumpControls={false}
                    showDownloadProgress={false}
                    showFilledProgress={false}
                    customIcons={{
                      play: <IoPlay />,
                      pause: <IoPause />,
                      rewind: <IoIosRewind />,
                      forward: <IoMdFastforward />,
                      volume: <GiSpeaker />
                    }}
                    onError={(e) => {
                      if (typeof e.target.error !== "undefined") {
                        errorDispatch({
                          type: "UPDATE_ERROR_STATE",
                          payload: {
                            errorStatusCode: 998,
                            errorUserMessage: "AudioPlayer General Error",
                            errorDevData: e.target.error.message.toString()
                          }
                        });
                      }
                    }}
                    onPlayError={(e) => {
                      errorDispatch({
                        type: "UPDATE_ERROR_STATE",
                        payload: {
                          errorStatusCode: 999,
                          errorUserMessage: "AudioPlayer OnPlay Error",
                          errorDevData: e.toString()
                        }
                      });
                    }}
                  />
                </div>
              </p>
              <select
                name={answerFieldID}
                data-testitem={testitem}
                defaultValue={value}
                id={actID + "_" + i + "_0"}
                onChange={props.onAnswerSelected}
              >
                <option value={value} disabled>
                  Select
                </option>
                {dropdown}
              </select>
            </div>
            <div className="sndAttempt">
              <p className="question">
                <span className="inlinePlay paused" file={queAudioFile[i]}>
                  Audio {numberLetter[num]}
                </span>
              </p>
              <select
                name={answerFieldID}
                data-testitem={testitem}
                id={actID + "_" + i + "_1"}
                onChange={props.onAnswerSelectedMod}
              >
                <option disabled>Select</option>
                {dropdown}
              </select>
            </div>
          </div>
        );
      }
    }
    return items;
  };

  return <div>{createAnswerField()}</div>;
}
