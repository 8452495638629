import React, { useRef, useState, useEffect, useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";
import { ErrorContext } from "../../../../../context/ErrorProvider";

export default function AssessmentPassage(props) {
  const [, errorDispatch] = useContext(ErrorContext);

  const lang = props.lang;
  const LO = props.actID;
  const num = props.optionNumber;
  const number = num + 1;
  const passageTitle = "Passage " + number;
  const testitem = props.testitem;
  const answerFieldsID = props.answerFieldsID;
  const answerFieldsIDTL = props.actID + "_" + parseInt(props.optionNumber + 3);
  const audioPlayerAns = useRef(null);
  const TL = "Transcription";
  const EN = "Translation";
  const RTL = props.dir;
  const dir = RTL ? "dir" : "";

  const itemID_fst_tl = props.actID + "_" + parseInt(props.optionNumber + 3) + "_0";
  const itemID_fst_en = answerFieldsID + "_0";

  const itemID_snd_tl = props.actID + "_" + parseInt(props.optionNumber + 3) + "_1";
  const itemID_snd_en = answerFieldsID + "_1";

  const createAudioPassage = () => {
    let main = [];
    let audioTitle = "Audio Passage " + number;
    main.push(
      <div key={number} id={number} onClick={props.onPlayMainAudio}>
        <div className="audPassage">{audioTitle} </div>
      </div>
    );
    return main;
  };

  const isAudio = (file) => typeof file === "string" && file.endsWith(".mp3");

  const questionItem = props.optionPassage.map((options, index) => {
    let items = [];
    if (index !== 0 && index !== 1) {
      let mp3 = options.substr(-4);
      if (mp3 === ".mp3") {
        let fn = "audio/" + lang + "/" + LO + "/" + options;
        if (fn !== null && isAudio(fn)) {
          items.push(
            <div key={fn} className="audioQ forceInlineBlock">
              <AudioPlayer
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                preload="metadata"
                // header={props.toggleState ? "(modified)" : null}
                // header={"________"}
                header={""}
                footer={null}
                hasDefaultKeyBindings={false}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                ref={audioPlayerAns["inline-" + index]}
                src={fn}
                showJumpControls={false}
                showDownloadProgress={false}
                showFilledProgress={false}
                customIcons={{
                  play: <IoPlay />,
                  pause: <IoPause />,
                  rewind: <IoIosRewind />,
                  forward: <IoMdFastforward />,
                  volume: <GiSpeaker />
                }}
                onError={(e) => {
                  if (typeof e.target.error !== "undefined") {
                    errorDispatch({
                      type: "UPDATE_ERROR_STATE",
                      payload: {
                        errorStatusCode: 998,
                        errorUserMessage: "AudioPlayer General Error",
                        errorDevData: e.target.error.message.toString()
                      }
                    });
                  }
                }}
                onPlayError={(e) => {
                  errorDispatch({
                    type: "UPDATE_ERROR_STATE",
                    payload: {
                      errorStatusCode: 999,
                      errorUserMessage: "AudioPlayer OnPlay Error",
                      errorDevData: e.toString()
                    }
                  });
                }}
              />
            </div>
          );
        }
      } else {
        let opKey = "option" + num;
        let trimOption = options.replace(/.*:/, "");
        trimOption = trimOption.replace(/.*__/, "");
        if (trimOption === "?") trimOption = "";
        trimOption = " " + trimOption + " ";
        items.push(<span key={opKey}>{trimOption}</span>);
      }
    }
    return items;
  });

  let field = "field" + num;
  const createAnswerField = () => {
    let textField = [];
    textField.push(
      <div className="" key={field}>
        <form autoComplete="off">
          <span className="answerSection">
            <label htmlFor="userInputQSA">
              {TL}
              <textarea
                className={[dir, "QSAAnswer"].join(" ")}
                autoComplete="off"
                name={answerFieldsIDTL}
                id={itemID_fst_tl}
                onBlur={props.onLoseFocusCheckNLP}
                onChange={props.onAnswerTlSelected}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </label>
          </span>
          <span className="answerSection">
            <label htmlFor="userInputQSA">
              {EN}
              <textarea
                className="QSAAnswer"
                autoComplete="off"
                name={answerFieldsID}
                data-testitem={testitem}
                id={itemID_fst_en}
                onBlur={props.onLoseFocusCheckNLP}
                onChange={props.onAnswerEnSelected}
                spellCheck={true}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
              {props.invalidEnInput.find((thisEl) => thisEl === answerFieldsID) && (
                <div className="invalidInputLanguageDiv">Please enter your translation in English.</div>
              )}
            </label>
          </span>
        </form>
      </div>
    );
    return textField;
  };

  const createAnswerField2 = () => {
    let textField2 = [];
    textField2.push(
      <div className="" key={field}>
        <form autoComplete="off">
          <span className="answerSection">
            <label htmlFor="userInputQSA">
              {TL}
              <textarea
                className={[dir, "QSAAnswer"].join(" ")}
                autoComplete="off"
                name={answerFieldsIDTL}
                id={itemID_snd_tl}
                onBlur={props.onLoseFocusCheckNLP}
                onChange={props.onAnswerTlSelectedMod}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </label>
          </span>
          <span className="answerSection">
            <label htmlFor="userInputQSA">
              {EN}
              <textarea
                style={{ border: "1px solid #73b1da" }}
                autoComplete="off"
                className="QSAAnswer"
                name={answerFieldsID}
                data-testitem={testitem}
                id={itemID_snd_en}
                onBlur={props.onLoseFocusCheckNLP}
                onChange={props.onAnswerEnSelectedMod}
                spellCheck={true}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
              {props.invalidEnInput.find((thisEl) => thisEl === answerFieldsID) && (
                <div className="invalidInputLanguageDiv">Please enter your translation in English.</div>
              )}
            </label>
          </span>
        </form>
      </div>
    );
    return textField2;
  };

  let clickCounter = props.clickCounter;
  let buildup = "";
  if (clickCounter === 0) buildup = createAnswerField();
  else buildup = createAnswerField2();

  return (
    <div>
      <br />
      <h2>{passageTitle}</h2>
      <h3>
        <div className="GRLC_content">
          <div className="GRLC_item">{createAudioPassage()}</div>
          <div className="GRLC_item">{questionItem}</div>
        </div>
      </h3>
      {buildup}
    </div>
  );
}
