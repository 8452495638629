import React, { useRef, useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
// import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";
import { ErrorContext } from "../../../../../context/ErrorProvider";

export default function Audio(props) {
  const [, errorDispatch] = useContext(ErrorContext);

  // const sanitizer = dompurify.sanitize;
  const LO = props.LO;
  const lang = props.lang;
  const mainAudio = "audio/" + lang + "/" + LO + "/" + props.mainAudio;
  const audioPlayer = useRef(null);

  const isAudio = (file) => typeof file === "string" && file.endsWith(".mp3");

  return (
    <div>
      <h2>Listen to the passage:</h2>
      {props.mainAudio !== null && mainAudio !== null && isAudio(mainAudio) && (
        <AudioPlayer
          preload="metadata"
          id="ODAPlayer2"
          autoPlay={false}
          autoPlayAfterSrcChange={false}
          header={
            <div className="rhap_textCenter">
              Main Passage {props.toggleState ? "(Modified)" : ""}
              <span className="modifiedFlag" />
            </div>
          }
          footer={null}
          customAdditionalControls={[]}
          ref={audioPlayer.main}
          src={mainAudio}
          customIcons={{
            play: <IoPlay />,
            pause: <IoPause />,
            rewind: <IoIosRewind />,
            forward: <IoMdFastforward />,
            volume: <GiSpeaker />
          }}
          onError={(e) => {
            if (typeof e.target.error !== "undefined") {
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: 998,
                  errorUserMessage: "AudioPlayer General Error",
                  errorDevData: e.target.error.message.toString()
                }
              });
            }
          }}
          onPlayError={(e) => {
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: 999,
                errorUserMessage: "AudioPlayer OnPlay Error",
                errorDevData: e.toString()
              }
            });
          }}
        />
      )}
      <h3>Give the meaning of the following words as used in the passage:</h3>
    </div>
  );
}
