import { useState, useEffect } from "react";
import JWT from "../contents/JWT";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";

export function useDisplayDiagnosticProfile(
  profileEvent,
  profileType,
  profileTotalGR,
  profileTotalRC,
  profileTotalLC,
  profileTotalALL,
  runDisplayDiagnosticProfile,
  checkGrDashboardDP,
  checkRcDashboardDP,
  checkLcDashboardDP
) {
  //** Global State Context Calls, links to context/AssessmentProvider.js */
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setLatestError] = useState(null);
  const navigate = useNavigate();
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
    retryCondition: (err) => {
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  useEffect(() => {
    if (profileEvent !== null && runDisplayDiagnosticProfile !== false) {
      setIsLoading(true);

      const trackingObject = {
        appName: "CMS-ODA",
        eventType: "UserViewedDiagnosticProfile",
        eventDetails: {
          sessionID: profileEvent.target.parentElement.id,
          modality: profileType,
          lang:null,
          CL:null
        },
        dateTime: new Date().toISOString(),
        location: window.location
      };
      if (window.location.hostname === "odatest.dliflc.app") {
        axios.post("/tag", trackingObject).catch((error) => {
          console.log(error);
        });
        } else {
        axios.post("/api/tag", trackingObject).catch((error) => {
          console.log(error);
        });
        }
      // const devURL = "";
      // const stagURL = "https://oda2stag.dliflc.edu";
      // const prodURL = "https://oda.dliflc.edu";
      // const port = ":5005";
      // const api = "/api/tag";
      // if (window.location.hostname === "localhost") {
      //   axios.post(devURL + api, trackingObject).catch((error) => {
      //     console.log(error);
      //   });
      // } else if (window.location.hostname === "oda2stag.dliflc.edu") {
      //   axios.post(stagURL + api, trackingObject).catch((error) => {
      //     console.log(error);
      //   });
      // } else {
      //   axios.post(prodURL + api, trackingObject).catch((error) => {
      //     console.log(error);
      //   });
      // }
      if (profileType === "grammar") {
        let grID = null;
        grID = profileEvent.target.parentElement.id;
        JWT.fetchData()
          .then((result) => {
            const grTotal = profileTotalGR;
            const grDashboardDP = checkGrDashboardDP;
            setData({
              navigateTo: "/profileGrammar",
              grID: grID,
              grTotal: grTotal,
              grDashboardDP: grDashboardDP
            });
          })
          .catch((error) => {
            setLatestError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (profileType === "reading") {
        let rcID = null;
        rcID = profileEvent.target.parentElement.id;
        JWT.fetchData()
          .then((result) => {
            const rcTotal = profileTotalRC;
            const rcDashboardDP = checkRcDashboardDP;
            setData({
              navigateTo: "/profile",
              rcID: rcID,
              rcTotal: rcTotal,
              rcDashboardDP: rcDashboardDP
            });
          })
          .catch((error) => {
            setLatestError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (profileType === "listening") {
        let lcID = null;
        lcID = profileEvent.target.parentElement.id;
        JWT.fetchData()
          .then((result) => {
            const lcTotal = profileTotalLC;
            const lcDashboardDP = checkLcDashboardDP;
            setData({
              navigateTo: "/profile",
              lcID: lcID,
              lcTotal: lcTotal,
              lcDashboardDP: lcDashboardDP
            });
          })
          .catch((error) => {
            setLatestError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [
    profileEvent,
    profileType,
    runDisplayDiagnosticProfile,
    isLoading,
    navigate,
    profileTotalGR,
    profileTotalRC,
    profileTotalLC,
    profileTotalALL
  ]);

  return [data, isLoading, error];
}
