import { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import "../../css/index.css";
import { AssessmentContext } from "../../context/AssessmentProvider";
import { ErrorContext } from "../../context/ErrorProvider";
import ErrorModal from "../../components/error/ErrorModal";
import HelpTooltip from "../../components/modals/helptooltip/HelpTooltip.json";
import NewTestTipsModal from "../../components/modals/NewTestTipsModal";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";

import headLogo from "../../img/logos/headerLogo.svg";
import headLogoSM from "../../img/logos/headerLogoSmall.svg";
import instructionIcon from "../../img/icons/instruction.svg";

import GLCB from "./templates/GLCB/GLCB";
import GLCC from "./templates/GLCC/GLCC";
import GLCI from "./templates/GLCI/GLCI";
import GLSA from "./templates/GLSA/GLSA";
import GLSB from "./templates/GLSB/GLSB";

import GMCA from "./templates/GMCA/GMCA";
import GMCB from "./templates/GMCB/GMCB";
import GMCI from "./templates/GMCI/GMCI";
import GMPG from "./templates/GMPG/GMPG";
import GMSA from "./templates/GMSA/GMSA";
import GMSG from "./templates/GMSG/GMSG";

const GrammarTest = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
    retryCondition: (err) => {
      console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  //Global State Context Calls, links to context/AssessmentProvider.js & context/ErrorProvider.js
  const [assessmentState] = useContext(AssessmentContext);
  const [modalErrorState, errorDispatch] = useContext(ErrorContext);

  const [clickNextComplete, setClickNextComplete] = useState(null);
  const [setNextComplete, setSetNextComplete] = useState(null);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [showTestTipsStart, setShowTestTipsStart] = useState(false);
  const [buttonWaiting, setButtonWaiting] = useState(false);

  const [sentTestletStart, setSentTestletStart] = useState(false);
  const [sentTestletEnd, setSentTestletEnd] = useState(false);
  const [questionStartSeconds, setQuestionStartSeconds] = useState(null);

  //let testletDB = props.location.state.testlet.testlets;
  let testletDB = location.state.testlet.testlets;
  //console.log("props.location.state:");
  //console.log(props.location.state);

  let progessLen = 0;
  //const resumePage = props.location.state.pageNum;
  const resumePage = location.state.pageNum;
  if (resumePage != undefined) {
    progessLen = parseInt(resumePage) + 1; //what page to start
    //testletDB = props.location.state.testlet;
    testletDB = location.state.testlet;
  }

  const [state, setState] = useState({
    counter: progessLen,
    questionId: progessLen + 1,
    template: testletDB[progessLen].template,
    language: testletDB[progessLen].language.description,
    modal: testletDB[progessLen].modality,
    showChild: true,
    testItems: [],
    testData: testletDB,
    currentItem: progessLen + 1,
    testletDBLen: testletDB.length,
    isCompleted: false
  });

  let testItems = [];
  testItems.length = state.testletDBLen;

  useEffect(() => {
    //componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
    window.addEventListener("popstate", (e) => {
      window.location.reload();
    });

    //show generic test tips modal to the user
    if (state.counter === 0) {
      setShowTestTipsStart(true);
    }
  }, []);

  useEffect(() => {
    //handle preferred fonts
    if (assessmentState.assessmentLanguage !== null) {
      const thisLangId = assessmentState.assessmentLanguage._id;
      // console.log(thisLangId);
      let forcedFont = null;
      if (thisLangId === "ara") {
        forcedFont = "Noto Naskh Arabic";
      } else if (thisLangId === "tur") {
        forcedFont = "Times New Roman";
      } else if (thisLangId === "kor" || thisLangId === "nkr") {
        forcedFont = "'Noto Sans KR Variable', sans-serif";
      } else if (thisLangId === "cmn") {
        forcedFont = "'Noto Sans TC Variable', sans-serif";
      } else {
        forcedFont = "'Verdana, Tahoma, Arial, sans-serif'!important";
        // forcedFont = "'Open Sans', sans-serif !important"
      }
      if (forcedFont !== null) {
        let tempInnerEls = document.querySelectorAll("div.templateInner div.textPassage");
        if (tempInnerEls !== null && tempInnerEls.length === 1) {
          tempInnerEls[0].style.fontFamily = forcedFont;
        } else if (tempInnerEls !== null && tempInnerEls.length > 1) {
          tempInnerEls.forEach((item, index) => {
            item.style.fontFamily = forcedFont;
          });
        }
      }
    }
  });

  //console.log watch for changes in (global) assessmentState
  useEffect(() => {
    // console.log("Current global state (below).");
    // console.log(assessmentState);
  }, [assessmentState]);

  const handleRefModalTestTips = useCallback(() => {
    setShowTestTipsStart(false);
  }, []);

  const callbackFunction = useCallback(
    (childData, inputName) => {
      const sessionID = assessmentState.assessmentSessionId;

      //calculate the total duration of the most recently completed question and append it
      const questionTotalSeconds = (Date.now() - questionStartSeconds) / 1000;
      setQuestionStartSeconds(null);
      let childDataTemp = JSON.parse(childData);
      childDataTemp.questionTotalSeconds = questionTotalSeconds;
      childData = childDataTemp;

      // console.log("---start debug section---");
      // console.log(sessionID);
      // console.log(inputName);
      // console.log(questionTotalSeconds);
      // console.log(childData);
      // console.log("---end debug section---");

      if (inputName === "next") {
        setButtonWaiting(true);

        axios({
          method: "post",
          headers: { "content-type": "application/json" },
          url: "/api/sessionGR/" + sessionID,
          data: childData
        })
          .then((res) => {
            setButtonWaiting(false);
            //console.log("SENDIND next SUCCESSFUL", res.data);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
                //global error dispatch call to show error modal if error received during api call
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: error.response.status,
                    errorUserMessage: "New Error",
                    errorDevData: error.response
                  }
                });
              }
            }
          });
      }
      if (inputName === "submit") {
        setSpinnerStatus(true); //show spinner while assessment is processing
        let getDate = new Date().toString();
        let endtime = '{"endtime":"' + getDate + '"}';

        axios({
          method: "post",
          headers: { "content-type": "application/json" },
          url: "/api/sessionGR/" + sessionID,
          data: childData
        })
          .then((res) => {
            //console.log("SENDING submit next SUCCESSFUL", res.data);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
                //global error dispatch call to show error modal if error received during api call
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: error.response.status,
                    errorUserMessage: "New Error",
                    errorDevData: error.response
                  }
                });
              }
            }
          });

        axios({
          method: "put",
          headers: { "content-type": "application/json" },
          url: "/api/sessionGR/" + sessionID,
          data: endtime
        })
          .then((res) => {
            //testlet ended tracking tag
            if (sentTestletEnd === false) {
              const trackingObjectFinished = {
                appName: "CMS-ODA",
                eventType: "UserFinishedTestlet",
                eventDetails: {
                  skill: "grammar",
                  lang: assessmentState.assessmentLanguage.displayName,
                  level: assessmentState.assessmentModuleRef,
                  testletName: state.testData[state.counter]._id,
                  template: state.testData[state.counter].template,
                  sessionID: assessmentState.sessionId,
                  duration: questionStartSeconds !== null ? (Date.now() - questionStartSeconds) / 1000 : null
                },
                dateTime: new Date().toISOString(),
                location: window.location
              };
              if (window.location.hostname === "odatest.dliflc.app") {
                axios.post(window.location.origin + "/tag", trackingObjectFinished).catch((error) => {
                  console.log(error);
                });
              } else {
                axios.post(window.location.origin + "/api/tag", trackingObjectFinished).catch((error) => {
                  console.log(error);
                });
              }
              setSentTestletEnd(true);
            }
            //assessment ended tracking tag
            const trackingObject = {
              appName: "CMS-ODA",
              eventType: "AssessmentCompleted",
              eventDetails: {
                type: "Grammar",
                sessionID: assessmentState.assessmentSessionId,
                userID: assessmentState.assessmentUserId,
                lang: state.language,
                CL: assessmentState.assessmentModuleRef,
                duration: null
              },
              dateTime: new Date().toISOString(),
              location: window.location
            };
            if (window.location.hostname === "odatest.dliflc.app") {
              axios.post("/tag", trackingObject).catch((error) => {
                console.log(error);
              });
            } else {
              axios.post("/api/tag", trackingObject).catch((error) => {
                console.log(error);
              });
            }

            setState({ ...state, sessionID: res.data.result._id });
            setButtonWaiting(false);
            //console.log("SENDIND submit getDate SUCCESSFUL", res.data.result._id);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
                //global error dispatch call to show error modal if error received during api call
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: error.response.status,
                    errorUserMessage: "New Error",
                    errorDevData: error.response
                  }
                });
              }
            }
          });

        //sends user to their diagnostic profile after 7.5s;
        setTimeout(() => {
          //console.log("inside the redirect for diagnostic profile now...");
          //console.log("using api/sessionGR/diagProfileTotal params:email: " + assessmentState.assessmentUserEmail);
          axios
            //.get("api/sessionGR/diagProfileTotal", { params: { email: assessmentState.assessmentUserEmail } })
            .get("api/sessionGR/diagProfileTotal/" + assessmentState.assessmentSessionId)
            .then((res) => {
              //console.log("profileGrammar grID " + assessmentState.assessmentSessionId + " && grTotal " + res.data);
              setState({ ...state, getGrTotal: res.data });
              navigate("/profileGrammar", {
                state: {
                  grID: assessmentState.assessmentSessionId,
                  grTotal: res.data
                }
              });
              setSpinnerStatus(false); //disable spinner, but it shouldn't matter at this point
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status >= 400 && error.response.status < 600) {
                  //global error dispatch call to show error modal if error received during api call
                  errorDispatch({
                    type: "UPDATE_ERROR_STATE",
                    payload: {
                      errorStatusCode: error.response.status,
                      errorUserMessage: "New Error",
                      errorDevData: error.response
                    }
                  });
                }
              }
            });
        }, 7500); //TODO: need a smarter check instead of a delay
      }
    },
    [state, questionStartSeconds, assessmentState, errorDispatch, navigate]
  );

  const submitButton = useCallback(() => {
    // console.log(
    //   "The grammar assessment process is complete. You can view your grammar diagnostic profile when you close this message."
    // );
    // if (sentTestletEnd === false) {
    //   const trackingObjectFinished = {
    //     appName: "CMS-ODA",
    //     eventType: "UserFinishedTestlet",
    //     eventDetails: {
    //       skill: "grammar",
    //       lang: assessmentState.assessmentLanguage.displayName,
    //       level: assessmentState.assessmentModuleRef,
    //       testletName: state.testData[state.counter]._id,
    //       template: state.testData[state.counter].template,
    //       sessionID: assessmentState.sessionId,
    //       duration: questionStartSeconds !== null ? (Date.now() - questionStartSeconds) / 1000 : null
    //     },
    //     dateTime: new Date().toISOString(),
    //     location: window.location
    //   };
    //   if (window.location.hostname === "odatest.dliflc.app") {
    //     axios.post(window.location.origin + "/tag", trackingObjectFinished).catch((error) => {
    //       console.log(error);
    //     });
    //   } else {
    //     axios.post(window.location.origin + "/api/tag", trackingObjectFinished).catch((error) => {
    //       console.log(error);
    //     });
    //   }
    //   setSentTestletEnd(true);
    // }
    // setSentTestletStart(false);
  }, []);

  /**
   * NOTE: next code set is unordered useEffects & functions,
   * so labeled parts 1-5 with //clickNextButton Order (#),
   * which is the logical order when the Next button is clicked
   */

  const handleNext = useCallback(() => {
    //clickNextButton Order (2)

    if (sentTestletEnd === false) {
      const trackingObjectFinished = {
        appName: "CMS-ODA",
        eventType: "UserFinishedTestlet",
        eventDetails: {
          skill: "grammar",
          lang: assessmentState.assessmentLanguage.displayName,
          level: assessmentState.assessmentModuleRef,
          testletName: state.testData[state.counter]._id,
          template: state.testData[state.counter].template,
          sessionID: assessmentState.sessionId,
          duration: questionStartSeconds !== null ? (Date.now() - questionStartSeconds) / 1000 : null
        },
        dateTime: new Date().toISOString(),
        location: window.location
      };
      if (window.location.hostname === "odatest.dliflc.app") {
        axios.post(window.location.origin + "/tag", trackingObjectFinished).catch((error) => {
          console.log(error);
        });
      } else {
        axios.post(window.location.origin + "/api/tag", trackingObjectFinished).catch((error) => {
          console.log(error);
        });
      }
      setSentTestletEnd(true);
    }

    let wrapperTop =
      document.querySelector(".wrapper").getBoundingClientRect() !== null
        ? document.querySelector(".wrapper").getBoundingClientRect()
        : { top: 0, right: 0, bottom: 0, left: 0 };
    window.scrollTo(0, wrapperTop.top);
    let progUnits = document.querySelectorAll(".progressUnit");
    let len = progUnits.length;
    progUnits.forEach(function (progUnit) {
      progUnit.classList.remove("current");
    });
    if (state.currentItem < len) {
      progUnits[state.currentItem].classList.add("current");
      progUnits[state.currentItem].classList.add("complete");
    }
    setState({ ...state, currentItem: state.currentItem + 1 });
    setClickNextComplete(true);
  }, [state]);

  const clickNextButton = useCallback(() => {
    //clickNextButton Order (1)
    if (clickNextComplete !== true) {
      setClickNextComplete(false);
      handleNext(); //sets state.currentItem
    }
  }, [handleNext, clickNextComplete]);

  const setNextQuestion = useCallback(() => {
    //clickNextButton Order (4)

    const counter = state.counter + 1;
    const questionId = state.questionId + 1;
    setState({
      ...state,
      counter: counter,
      questionId: questionId,
      template: state.testData[counter].template,
      showChild: false
    });
    setSentTestletStart(false);
    setSentTestletEnd(false);
    setClickNextComplete(null); //reset clickNext button
    setSetNextComplete(true);
  }, [state]);

  useEffect(() => {
    //clickNextButton Order (3)
    if (state.questionId < state.testletDBLen && clickNextComplete) {
      setSetNextComplete(false);
      setNextQuestion(); //sets state.counter, state.questionId, state.template, state.showChild
    }
  }, [clickNextComplete, state, setNextQuestion]);

  useEffect(() => {
    //clickNextButton Order (5)
    if (setNextComplete === true) {
      setState({
        ...state,
        showChild: true
      });
      setSetNextComplete(null); //reset setnext functionality
    }
  }, [state, setNextComplete]);

  /**
   * end unordered Next button logic section
   */

  const createProgGrid = () => {
    let item = [];
    for (let i = 1; i < state.testletDBLen + 1; i++) {
      if (state.counter === 0) {
        if (i === 1) {
          item.push(
            (item = [
              <div className="progressUnit complete current" key={i}>
                <span>1</span>
              </div>
            ])
          );
        } else {
          item.push(
            <div className="progressUnit" key={i}>
              <span>{i}</span>
            </div>
          );
        }
      } else {
        let len = state.currentItem;
        if (i < len) {
          item.push(
            <div className="progressUnit complete" key={i}>
              <span>{i}</span>
            </div>
          );
        } else if (i === len) {
          item.push(
            <div className="progressUnit complete current" key={i}>
              <span>{i}</span>
            </div>
          );
        } else {
          item.push(
            <div className="progressUnit" key={i}>
              <span>{i}</span>
            </div>
          );
        }
      }
    }
    return item;
  };

  //Capitalize the first letter of a string
  const capitalizeName = (name) => {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };

  const asterisk = <span className="dataLeftSmall_grMain_asterisk">*</span>;

  const renderTemplate = useCallback(() => {
    if (state.showChild) {
      if (questionStartSeconds === null) {
        setQuestionStartSeconds(Date.now());
      }
      let counter = state.counter;

      //event tag for tracking starting a template
      if (sentTestletStart === false) {
        const trackingObject = {
          appName: "CMS-ODA",
          eventType: "UserStartedTestlet",
          eventDetails: {
            skill: "grammar",
            lang: assessmentState.assessmentLanguage.displayName,
            level: assessmentState.assessmentModuleRef,
            testletName: state.testData[state.counter]._id,
            template: state.testData[state.counter].template,
            sessionID: assessmentState.sessionId
          },
          dateTime: new Date().toISOString(),
          location: window.location
        };
        if (window.location.hostname === "odatest.dliflc.app") {
          axios.post(window.location.origin + "/tag", trackingObject).catch((error) => {
            console.log(error);
          });
        } else {
          axios.post(window.location.origin + "/api/tag", trackingObject).catch((error) => {
            console.log(error);
          });
        }
        setSentTestletStart(true);
      }

      if (state.template === "GLCB") {
        return (
          <GLCB
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      } else if (state.template === "GLCC") {
        return (
          <GLCC
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      } else if (state.template === "GLCI") {
        return (
          <GLCI
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      } else if (state.template === "GLSA") {
        return (
          <GLSA
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      } else if (state.template === "GLSB") {
        return (
          <GLSB
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      } else if (state.template === "GMCA") {
        return (
          <GMCA
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      } else if (state.template === "GMCB") {
        return (
          <GMCB
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      } else if (state.template === "GMCI") {
        return (
          <GMCI
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      } else if (state.template === "GMPG") {
        return (
          <GMPG
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      } else if (state.template === "GMSA") {
        return (
          <GMSA
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      } else if (state.template === "GMSG") {
        return (
          <GMSG
            counter={state.counter}
            testData={state.testData[counter]}
            parentCallback={callbackFunction}
            nextButton={clickNextButton}
            submitButton={submitButton}
            navLen={testItems.length}
            navCrrtItem={state.currentItem}
            buttonWaiting={buttonWaiting}
          />
        );
      }
    } else return null;
  }, [state, callbackFunction, clickNextButton, submitButton, buttonWaiting, testItems.length]);

  return (
    <div>
      <div className="assessmentHeader">
        <div className="assHeaderTop">
          <Link to="/dashboard">
            <img
              className="logo"
              src={headLogo}
              alt="Online Diagnostic Assessment Logo"
              title="Click here to return to your assessment dashboard."
            />
          </Link>
        </div>
        <Link to="/dashboard">
          <img
            className="logo"
            src={headLogoSM}
            alt="Online Diagnostic Assessment Logo"
            title="Click here to return to your assessment dashboard."
          />
        </Link>
        <h3>
          {assessmentState.assessmentLanguage.description !== null
            ? assessmentState.assessmentLanguage.description
                .replace(
                  /^(North_Korean|North Korean|Korean\/North Korean|Korean\/North_Korean)$/i,
                  "Korean (South & North)"
                )
                .replace(/^Korean$/i, "Korean (South)")
                .replace("_", " ")
            : ""}{" "}
          {capitalizeName(state.modal)} Assessment
        </h3>
        {!spinnerStatus && (
          <p>
            <span className="hoverinstruction" data-hover={HelpTooltip[0][state.template]}>
              <span className="account" style={{ float: "right", marginTop: "-40px" }}>
                <img id="instrcutionIcon" src={instructionIcon} />
              </span>
            </span>
          </p>
        )}
      </div>
      {!spinnerStatus && <div className="progressGrid">{createProgGrid()}</div>}
      {spinnerStatus ? (
        <LoadingSpinner msg="Please wait while your personalized diagnostic profile is created..." size="small" />
      ) : (
        renderTemplate()
      )}
      <div className="clearFix" />

      {showTestTipsStart && (
        <NewTestTipsModal handleRefModalTestTips={handleRefModalTestTips} template={state.template} />
      )}
      <ErrorModal
        errorStatusCode={modalErrorState.errorStatusCode}
        errorUserMessage={modalErrorState.errorUserMessage}
        errorDevData={modalErrorState.errorDevData}
      />
    </div>
  );
};

export default GrammarTest;
